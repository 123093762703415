import React from 'react';
import { Logo } from '@sm/webassets/Logos';
import { Typography } from '@wds/typography';
import { Box } from '@wds/box';
import classNames from 'classnames';

import useStyles from './useStyles';

export type HeaderColor = 'midnight' | 'concord' | 'sabaeus' | 'vine';
export type Props = {
  copy: string;
  hasLogo?: boolean;
  headerColor?: HeaderColor;
  headerType?: 'thanks' | 'static';
  icon?: React.ReactElement;
};

const EndpageHeader = ({
  copy,
  hasLogo = false,
  headerColor = 'concord',
  headerType = 'thanks',
  icon,
}: Props): React.ReactElement => {
  const staticHeader = headerType === 'static';
  const {
    header,
    thanks,
    icon: iconClass,
    logo,
    copy: copyClass,
  } = useStyles({
    headerColor,
    hasLogo,
    staticHeader,
  });

  return (
    <Box
      p={6}
      display="flex"
      flexJustify="center"
      className={classNames(header, staticHeader ? '' : thanks)}
      data-testid="EndpageHeader"
    >
      {hasLogo && <Logo className={logo} color="alt" />}
      {icon && !hasLogo && <span className={iconClass}>{icon}</span>}
      <Typography color="light" component="span" variant="pageTitle" align="center">
        <span className={copyClass}>{copy}</span>
      </Typography>
    </Box>
  );
};

export default EndpageHeader;
