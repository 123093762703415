import React from 'react';
import { defineMessages, t } from '@sm/intl';
import { Box } from '@wds/box';
import { createURL } from '@sm/utils';
import { Logo, LogoWithText } from '@sm/webassets/Logos';
import { generateMetricsAttribute } from '@sm/metrics';
import useStyles from './useStyles';

export const COPY = defineMessages({
  LOGO_ARIA_LABEL: {
    id: 'SimpleLogoHeader.LogoAriaLabel',
    defaultMessage: 'SurveyMonkey home',
    description: '[Type: Header][Vis: med] - The SurveyMonkey Logo shown at the top of the page',
  },
});

const SimpleLogoHeader = ({ utSource, utSource2 }: Props): React.ReactElement => {
  const { header, logo, text, goldie, mobileLogo, desktopLogo } = useStyles();
  const utSource3 = 'SimpleLogoHeader';

  return (
    <Box data-testid="SimpleLogoHeader__Container" className={header}>
      <Box mt={6} display="flex" flexJustify="center" className={mobileLogo}>
        <a
          aria-label={t(COPY.LOGO_ARIA_LABEL)}
          data-testid="SimpleLogoHeader__Goldie"
          href={createURL('/', {
            ut_source: utSource,
            ut_source2: utSource2,
            ut_source3: utSource3,
            ut_ctatext: 'Logo',
          })}
          data-sm-metrics={generateMetricsAttribute({
            data: {
              amplitudeEvent: 'endpage user interaction',
              itemSelected: 'logo',
              log: {
                message: `${utSource}-${utSource2}-${utSource3}-Goldie-cta`,
              },
            },
          })}
          className={goldie}
        >
          <Logo className={text} />
        </a>
      </Box>
      <Box mt={7} ml={7} display="block" className={desktopLogo}>
        <a
          aria-label={t(COPY.LOGO_ARIA_LABEL)}
          data-testid="SimpleLogoHeader__Logo"
          href={createURL('/', {
            ut_source: utSource,
            ut_source2: utSource2,
            ut_source3: utSource3,
            ut_ctatext: 'LogoWithText',
          })}
          data-sm-metrics={generateMetricsAttribute({
            data: {
              amplitudeEvent: 'endpage user interaction',
              itemSelected: 'logo',
              log: {
                message: `${utSource}-${utSource2}-${utSource3}-LogoWithText`,
              },
            },
          })}
          className={logo}
        >
          <LogoWithText className={text} />
        </a>
      </Box>
    </Box>
  );
};

export type Props = {
  utSource: string;
  utSource2: string;
};

export default SimpleLogoHeader;
