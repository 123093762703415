import React from 'react';
import { createURL } from '@sm/utils';
import { generateMetricsAttribute } from '@sm/metrics';
import { Button } from '../core/Button';

const SplitHeroButton = ({
  href,
  text,
  metricsData,
  variant = 'default',
  amplitudeEvent,
  templateType,
  surveyId,
  collectorId,
  respondentId,
  utSource,
  utSource2,
  utSource3,
}: {
  href: string;
  text: string;
  metricsData: { itemSelected: string; ut_ctatext: string };
  variant?: 'default' | 'ghost';
  amplitudeEvent: string;
  templateType: string;
  surveyId: string;
  collectorId: string;
  respondentId: string;
  utSource: string;
  utSource2: string;
  utSource3: string;
}): React.ReactElement => (
  <Button
    size="lg"
    variant={variant === 'ghost' ? 'ghost' : undefined}
    color={variant === 'ghost' ? 'alt' : undefined}
    onDark={variant === 'ghost'}
    href={createURL(href, {
      ut_source: utSource,
      ut_source2: utSource2,
      ut_source3: utSource3,
      ut_ctatext: metricsData.ut_ctatext,
    })}
    data-sm-metrics={generateMetricsAttribute({
      data: {
        amplitudeEvent,
        itemSelected: metricsData.itemSelected,
        templateType,
        surveyId,
        collectorId,
        respondentId,
        log: {
          message: `${utSource}-${utSource2}-${utSource3}`,
        },
      },
    })}
  >
    {text}
  </Button>
);

export default SplitHeroButton;
