import { Theme } from '@wds/styles';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: Theme) => {
  const mediaQueryMinScreenLG = `@media (min-width: ${theme.breakpoints.lg}px)`;

  return {
    header: {
      // uses wider breakpoint than Wrench
      maxWidth: 1440,
      margin: '0 auto',
    },
    text: {
      fontSize: theme.type.fontSize.hero2,
    },
    logo: {
      width: '247px',
      height: '37px',
      display: 'block',
    },
    goldie: {
      width: '40px',
      height: '28px',
      '&:focus, &:hover': {
        textDecoration: 'none',
      },
    },
    mobileLogo: {
      display: 'flex',
      [mediaQueryMinScreenLG]: {
        display: 'none',
      },
    },
    desktopLogo: {
      display: 'none',
      [mediaQueryMinScreenLG]: {
        display: 'block',
      },
    },
  };
});

export default useStyles;
