import { createUseStyles } from 'react-jss';
import { CmsWrenchTheme } from '~app/styles/cmsTheme';

const useStyle = createUseStyles((theme: CmsWrenchTheme) => {
  const mediaQueryMinScreenMD = `@media (min-width: ${theme.breakpoints.md}px)`;
  const mediaQueryMinScreenLG = `@media (min-width: ${theme.breakpoints.lg}px)`;

  return {
    darkBackground: {
      backgroundColor: theme.brandColors.primary.vine,
      paddingRight: theme.spacing[5],
      paddingLeft: theme.spacing[5],
      paddingTop: theme.spacing[6],
      [mediaQueryMinScreenMD]: {
        paddingTop: theme.spacing[10],
      },
    },

    buttonBox: {
      display: 'block',
      [mediaQueryMinScreenMD]: {
        display: 'flex',
      },
    },

    headerFontSize: {
      textWrap: 'balance',
      // The wrench Typography used does not allow addition of a classname
      '& > section': {
        fontSize: '34px',
        [mediaQueryMinScreenLG]: {
          fontSize: '46px',
        },
      },
    },

    subHeaderFontSize: {
      // The wrench Typography used does not allow addition of a classname
      '& > section': {
        fontSize: '16px',
        [mediaQueryMinScreenLG]: {
          fontSize: '18px',
        },
      },
    },

    ctaForm: {
      margin: '0 auto',
      maxWidth: '380px',
    },

    logo: {
      height: 24,
      [mediaQueryMinScreenMD]: {
        height: 44,
      },
    },

    subHeader: {
      maxWidth: 400,
      [mediaQueryMinScreenMD]: {
        maxWidth: 600,
      },
      [mediaQueryMinScreenLG]: {
        maxWidth: 1000,
      },
    },

    bold: {
      fontWeight: 500,
    },

    strangeBox: {
      maxWidth: 500,
    },

    ctaWrapper: {
      maxWidth: 548,
    },

    headerDesktopPadding: {
      paddingTop: theme.spacing[5],
      [mediaQueryMinScreenMD]: {
        paddingBottom: theme.spacing[7],
        paddingTop: theme.spacing[9],
      },
    },

    imagePlacement: {
      width: '100%',
      maxWidth: 604,
      [mediaQueryMinScreenMD]: {
        paddingLeft: theme.spacing[4],
      },
      [mediaQueryMinScreenLG]: {
        paddingLeft: 0,
      },
    },
  };
});

export default useStyle;
