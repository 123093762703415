import { createUseStyles } from 'react-jss';
import { CmsWrenchTheme } from '../../styles/cmsTheme';

const useStyles = createUseStyles((theme: CmsWrenchTheme) => {
  const mediaQueryMinScreenSM = `@media (min-width: ${theme.breakpoints.sm}px)`;

  return {
    logo: {
      height: 37,
      width: '90%',
      [mediaQueryMinScreenSM]: {
        width: 'auto',
      },
    },

    text: {
      width: '90%',
      fontSize: 40,
      [mediaQueryMinScreenSM]: {
        width: 'auto',
      },
    },

    smSimpleHero: {
      backgroundColor: theme.brandColors.primary.midnight,
    },
    smWebRespWebButton: {
      '& > a': {
        backgroundColor: theme.brandColors.accent.bumblebee,
        color: theme.brandColors.neutral.charcoal,
        border: 'none',
        '&:hover, &:focus': {
          backgroundColor: theme.endpageColors.onHover.lighterBumblebee,
          textDecoration: 'none',
        },
      },
    },
    smGoldie: {
      '&:hover, &:focus': {
        textDecoration: 'none',
      },
    },
    heading: {
      fontWeight: '500',
    },
  };
});

export default useStyles;
