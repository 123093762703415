import { createUseStyles } from 'react-jss';
import { CmsWrenchTheme } from '~app/styles/cmsTheme';
import { HeaderColorList } from '.';

type StyleProps = {
  hasLogo: boolean | undefined;
  headerColor: HeaderColorList;
  staticHeader: boolean;
};

const useStyles = createUseStyles(({ type, elevation, brandColors, breakpoints, spacing }: CmsWrenchTheme) => ({
  '@keyframes notice': {
    '20%': {
      top: 0,
    },
    '80%': {
      top: 0,
    },
  },
  header: ({ headerColor, hasLogo, staticHeader }: StyleProps) => ({
    zIndex: elevation.sky,
    color: brandColors.neutral.white,
    backgroundColor: brandColors.primary[headerColor],
    wordWrap: 'break-word',
    flexDirection: staticHeader ? 'row' : 'column',
    padding: staticHeader ? spacing[3] : spacing[6],
    [`@media (min-width: ${breakpoints.md}px)`]: {
      flexDirection: hasLogo ? 'column' : 'row',
    },
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      padding: staticHeader ? spacing[5] : spacing[6],
    },
  }),
  thanks: {
    position: 'absolute',
    top: '-500px',
    boxSizing: 'border-box',
    width: '100%',
    animationName: '$notice',
    animationDuration: '7s',
    animationDelay: '2s',
  },

  icon: {
    fontSize: type.fontSize.pageTitle,
    color: brandColors.neutral.white,
    marginRight: spacing[2],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& svg': {
      stroke: brandColors.neutral.white,
      strokeWidth: 0.5,
      overflow: 'visible',
    },
  },
  logo: ({ staticHeader }: StyleProps) => ({
    fontSize: staticHeader ? type.fontSize.sectionTitleSm : (type.fontSize.pageTitle as number) + 6,
    alignSelf: staticHeader ? 'center' : 'auto',
    paddingTop: staticHeader ? spacing[2] : 0,
    paddingRight: staticHeader ? spacing[5] : 0,
    marginBottom: staticHeader ? 0 : spacing[1],
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      marginBottom: spacing[3],
      fontSize: (type.fontSize.pageTitle as number) + 6,
    },
  }),
  copy: ({ headerColor, staticHeader }: StyleProps) => ({
    color: brandColors.neutral.white,
    fontWeight: 500,
    verticalAlign: staticHeader && headerColor !== 'vine' ? 'middle' : undefined,
    textAlign: staticHeader && headerColor !== 'vine' ? 'center' : undefined,
    fontSize: staticHeader ? type.fontSize.body : type.fontSize.sectionTitle,
    [`@media (min-width: ${breakpoints.lg}px)`]: {
      fontSize: type.fontSize.pageTitle,
    },
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      fontSize: type.fontSize.sectionTitle,
    },
  }),
}));
export default useStyles;
