import { createUseStyles } from 'react-jss';

type StyleProps = {
  darkBackground: boolean;
};

const useStyles = createUseStyles(() => ({
  logo: ({ darkBackground }: StyleProps) => ({
    filter: darkBackground ? 'invert(0)' : 'invert(1)',
    display: 'block',
    margin: [0, 'auto'],
  }),
}));

export default useStyles;
