import { createUseStyles } from 'react-jss';
import { CmsWrenchTheme } from '~app/styles/cmsTheme';
import { ColorProp } from '.';

type StyleProps = {
  color: ColorProp;
  showLogoWall: boolean;
};

const useStyles = createUseStyles((theme: CmsWrenchTheme) => ({
  container: ({ color }: StyleProps) => {
    const backgroundColor = (): string | undefined => {
      const COLOR_STRING_TO_VALUE = {
        sabaeus: theme.brandColors.primary.sabaeus,
        canvas: theme.brandColors.neutral.canvas,
        vine: theme.brandColors.primary.vine,
      };
      return COLOR_STRING_TO_VALUE[color];
    };
    return {
      backgroundColor: backgroundColor(),
    };
  },
  button: ({ color }: StyleProps) => ({
    '& > *': {
      backgroundColor: color === 'sabaeus' ? theme.brandColors.neutral.white : undefined,
      borderColor: color === 'sabaeus' ? theme.brandColors.neutral.white : undefined,
      color: color === 'sabaeus' ? theme.brandColors.primary.sabaeus : undefined,
      '&:hover, &:focus': {
        backgroundColor: color === 'sabaeus' ? theme.endpageColors.onHover.transparentWhite : undefined,
        borderColor: color === 'sabaeus' ? theme.endpageColors.onHover.transparentWhite : undefined,
        textDecoration: 'none',
      },
      '&:focus-visible': {
        // default focus outline not visible for some reason, so we have to apply manually
        outline: '2px solid #007aff',
      },
    },
  }),
  copyElement: {
    maxWidth: 550,
  },
  copyText: ({ color, showLogoWall }: StyleProps) => {
    let textColor: string | undefined;
    if (color === 'canvas' && !showLogoWall) {
      textColor = theme.brandColors.primary.sabaeus;
    } else if (color === 'sabaeus' || color === 'vine') {
      textColor = theme.brandColors.neutral.white;
    }
    return {
      color: textColor,
      fontWeight: 500,
    };
  },
}));

export default useStyles;
