import { createUseStyles } from 'react-jss';
import { CtaColor } from '.';

import { CmsWrenchTheme } from '~app/styles/cmsTheme';

type StyleProps = { ctaColor: CtaColor };

const hoverColors = {
  classic: {
    sabaeus: '#00ce78',
    midnight: '#065497',
  },
  standard: {
    sabaeus: '#bdf3dc',
    concord: '#dad1dc',
    midnight: '#a8c6e0',
  },
};

const toFontSizeNumber = (fontSize: string | number): number => {
  if (typeof fontSize === 'number') {
    return fontSize;
  }
  return Number.parseFloat(fontSize);
};

const useStyles = createUseStyles((theme: CmsWrenchTheme) => {
  const brandColors = theme.brandColors.primary;
  type BrandColor = keyof typeof brandColors;
  return {
    container: {
      textAlign: 'center',
      [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
        maxWidth: '700px',
      },
    },
    copy: {
      width: '66%',
      '& p': {
        fontSize: `${toFontSizeNumber(theme.type.fontSize.body) * 1.25}px`,
      },
    },
    logo: {
      height: '80px',
    },
    button: ({ ctaColor }: StyleProps) => {
      const backgroundColor = (hover?: boolean): string => {
        if (!hover) {
          return ctaColor.includes('classic') ? brandColors[ctaColor.split('-')[1] as BrandColor] : '#FFF';
        }
        if (hover) {
          return ctaColor.includes('classic')
            ? hoverColors.classic[ctaColor.split('-')[1] as keyof typeof hoverColors.classic]
            : hoverColors.standard[ctaColor as keyof typeof hoverColors.standard];
        }
        return '#fff';
      };

      return {
        '& > *': {
          color: ctaColor.includes('classic') ? '#fff' : brandColors[ctaColor as BrandColor],
          backgroundColor: backgroundColor(),
          borderColor: backgroundColor(),

          '&:hover, &:focus': {
            backgroundColor: backgroundColor(true),
            borderColor: backgroundColor(true),
            textDecoration: 'none',
          },
          '&:focus-visible': {
            // default focus outline not visible for some reason, so we have to apply manually
            outline: '2px solid #007aff',
          },
        },
      };
    },
  };
});

export default useStyles;
