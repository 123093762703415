import React from 'react';
import { Box } from '@wds/box';
import { Grid } from '@wds/grid';
import ProductCalloutItem, { ProductCalloutItemProps } from '~app/components/ProductCalloutItem';

import useStyles from './useStyles';

export type ProductsCalloutListItem = Omit<ProductCalloutItemProps, 'utSource' | 'hideCTAs'>;

export type Props = {
  calloutItem1: ProductsCalloutListItem;
  calloutItem2: ProductsCalloutListItem;
  utSource: string;
  hideCTAs?: boolean;
};

const ProductsCalloutList = ({ calloutItem1, calloutItem2, utSource, hideCTAs = false }: Props): React.ReactElement => {
  const { item1, item2 } = useStyles({ itemColor1: calloutItem1.ctaColor, itemColor2: calloutItem2.ctaColor });
  return (
    <Box data-testid="ProductsCalloutList">
      <Grid fluid noGutters noBleeds>
        <Grid.Item lg={6} xs={12}>
          <Box className={item1}>
            <ProductCalloutItem {...calloutItem1} utSource={utSource} hideCTAs={hideCTAs} />
          </Box>
        </Grid.Item>
        <Grid.Item lg={6} xs={12}>
          <Box className={item2}>
            <ProductCalloutItem {...calloutItem2} utSource={utSource} hideCTAs={hideCTAs} />
          </Box>
        </Grid.Item>
      </Grid>
    </Box>
  );
};

export default ProductsCalloutList;
