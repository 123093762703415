import React, { useContext } from 'react';
import { Desc, t } from '@sm/intl';
import { generateMetricsAttribute } from '@sm/metrics';
import { StaticContext } from '@sm/webassets/StaticContext';
import { createURL } from '@sm/utils';
import { Box } from '@wds/box';
import { Typography } from '@wds/typography';
import { Button } from '~app/components/core/Button';

import useStyles from './useStyles';
import LogoWall from '../LogoWall';

export type ColorProp = 'sabaeus' | 'canvas' | 'vine';

type CopyTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'div';

export type Props = {
  copy: string;
  copyTag?: CopyTag;
  ctaText: Desc;
  ctaUsage: string;
  color?: ColorProp;
  utSource: string;
  utSource2: string;
  hideCTAs?: boolean;
  showLogoWall?: boolean;
  route?: string;
};

const FortuneCallout = ({
  color = 'canvas',
  copy,
  copyTag = 'h3',
  ctaText,
  ctaUsage,
  utSource,
  utSource2,
  hideCTAs = false,
  showLogoWall = false,
  route = '/user/sign-up/',
}: Props): React.ReactElement => {
  const { container, button, copyElement, copyText } = useStyles({ color, showLogoWall });
  const utSource3 = 'FortuneCallout';
  const {
    smParams: { surveyId, collectorId, respondentId },
    templateType,
  } = useContext(StaticContext);

  return (
    <Box
      data-testid="FortuneCallout"
      display="flex"
      flexAlign="center"
      flexDirection="column"
      className={container}
      pb={8}
    >
      <Box className={copyElement} pt={7} pb={5} pr={3} pl={3}>
        <Typography component={copyTag} align="center" variant="sectionTitle">
          <span className={copyText}>{copy}</span>
        </Typography>
      </Box>
      {showLogoWall && <LogoWall darkBackground={color === 'sabaeus' || color === 'vine'} />}
      {!hideCTAs && (
        <Box mt={5}>
          <Button
            className={button}
            size="lg"
            href={createURL(route, {
              ut_source: utSource,
              ut_source2: utSource2,
              ut_source3: utSource3,
              ut_ctatext: ctaText.defaultMessage ?? '',
            })}
            data-sm-metrics={generateMetricsAttribute({
              data: {
                amplitudeEvent: 'endpage user interaction',
                itemSelected: ctaUsage,
                templateType,
                surveyId,
                collectorId,
                respondentId,
                log: {
                  message: `${utSource}-${utSource2}-${utSource3}-${ctaText.defaultMessage}`,
                },
              },
            })}
          >
            {t(ctaText)}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default FortuneCallout;
