import React, { useContext } from 'react';
import { Desc, t } from '@sm/intl';
import { Box } from '@wds/box';
import { StaticContext } from '@sm/webassets/StaticContext';
import { Typography, TypographyColor } from '@wds/typography';
import { generateMetricsAttribute } from '@sm/metrics';
import classnames from 'classnames';

import { Button } from '~app/components/core/Button';

import useStyles from './useStyles';

export type CtaColor = 'classic-sabaeus' | 'classic-midnight' | 'sabaeus' | 'concord' | 'midnight';

export type Props = {
  icon: string;
  iconAlt?: string;
  heading: string;
  headingTag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div' | 'span' | 'p';
  copy: string;
  href: string;
  utSource: string;
  ctaText: Desc;
  ctaUsage: string;
  ctaColor: CtaColor;
  name: string;
  className?: string;
  hideCTAs?: boolean;
  color?: TypographyColor;
};

const ProductCalloutItem = ({
  icon,
  iconAlt = '',
  heading,
  headingTag = 'h3',
  copy,
  href,
  utSource,
  ctaText,
  ctaUsage,
  ctaColor,
  name,
  className,
  hideCTAs = false,
  color = 'light',
}: Props): React.ReactElement => {
  const { container, copy: copyClass, logo: logoClass, button } = useStyles({ ctaColor });
  const containerClasses = classnames(container, className);
  const {
    smParams: { surveyId, collectorId, respondentId },
    templateType,
  } = useContext(StaticContext);
  return (
    <Box
      display="flex"
      flexDirection="column"
      flexAlign="center"
      pr={5}
      pl={5}
      pb={7}
      pt={7}
      data-testid="ProductCalloutItem"
      className={containerClasses}
    >
      <Box mb={5}>
        <img alt={iconAlt} role={!iconAlt ? 'presentation' : undefined} src={icon} className={logoClass} />
      </Box>
      <Typography component={headingTag} color={color} variant="sectionTitle" align="center">
        <strong data-testid="ProductCalloutItem__Heading">{heading}</strong>
      </Typography>
      <Box mt={5} mb={5} className={copyClass}>
        <Typography color={color} component="p" variant="body" align="center">
          {copy}
        </Typography>
      </Box>
      {!hideCTAs && (
        <Button
          className={button}
          href={href}
          size="lg"
          data-sm-metrics={generateMetricsAttribute({
            data: {
              amplitudeEvent: 'endpage user interaction',
              itemSelected: ctaUsage,
              templateType,
              surveyId,
              collectorId,
              respondentId,
              log: {
                message: `${utSource}-${name}-${ctaText.defaultMessage}`,
              },
            },
          })}
        >
          {t(ctaText)}
        </Button>
      )}
    </Box>
  );
};

export default ProductCalloutItem;
