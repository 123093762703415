import { createUseStyles } from 'react-jss';
import { CtaColor } from '../ProductCalloutItem';

import { CmsWrenchTheme } from '~app/styles/cmsTheme';

type StyleProps = {
  itemColor1: CtaColor;
  itemColor2: CtaColor;
};

const useStyles = createUseStyles((theme: CmsWrenchTheme) => {
  const brandColors = theme.brandColors.primary;
  type BrandColor = keyof typeof brandColors;
  return {
    item1: ({ itemColor1 }: StyleProps) => ({
      backgroundColor: brandColors[itemColor1 as BrandColor] ?? '#FFF',
      height: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
        justifyContent: 'center',
      },
    }),
    item2: ({ itemColor2 }: StyleProps) => ({
      backgroundColor: brandColors[itemColor2 as BrandColor] ?? '#FFF',
      height: '100%',
    }),
  };
});

export default useStyles;
