import React from 'react';
import { Typography } from '@wds/typography';
import { Box } from '@wds/box';
import useStyles from './useStyles';
import ColoredLogoWithText from '~app/components/surveyThanksLogo/ColoredLogoWithText';

export type Props = {
  boldHeader: string;
  splitHeroDarkBackground?: boolean;
  subHeader: string;
  utSource: string;
  utSource2: string;
};

const HeroTextBanner = ({
  boldHeader,
  splitHeroDarkBackground,
  subHeader: subHeaderContent,
  utSource,
  utSource2,
}: Props): React.ReactElement => {
  const { subHeader, bold, headerDesktopPadding, headerFontSize, subHeaderFontSize } = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      className={headerDesktopPadding}
      pt={9}
      pr={3}
      pl={3}
    >
      <ColoredLogoWithText utSource={utSource} utSource2={utSource2} />
      <Box className={headerFontSize}>
        <Typography
          component="section"
          variant="hero1"
          align="center"
          color={splitHeroDarkBackground ? 'light' : 'dark'}
        >
          <span className={bold}>{boldHeader}</span>
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <Box pt={3} className={`${subHeader} ${subHeaderFontSize}`}>
          <Typography
            align="center"
            component="section"
            variant="cardTitle"
            color={splitHeroDarkBackground ? 'light' : 'dark'}
          >
            {subHeaderContent}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default HeroTextBanner;
